import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public newExperienceStepOne = new BehaviorSubject<any>(null);
  public newExperienceStepTwo = new BehaviorSubject<any>(null);
  public newExperienceStepThree = new BehaviorSubject<any>(null);
  public newExperienceStepFour = new BehaviorSubject<any>(null);
  public newExperienceStepFive = new BehaviorSubject<any>(null);
  public newExperienceStepSix = new BehaviorSubject<any>(null);
  public newExperienceStepSeven = new BehaviorSubject<any>(null);
  public profileStore = new BehaviorSubject<any>(null);
  public calculatePrice = new BehaviorSubject<any>(null);
  public issuer = new BehaviorSubject<any>(null);
  public email = new BehaviorSubject<string>('');
  public tokenCode = new BehaviorSubject<string>('');

  constructor() { }

  get getEmail(): any {
    return this.email.getValue();
  }

  get getExperienceOne(): any {
    return this.newExperienceStepOne.getValue();
  }

  get getExperienceTwo(): any {
    return this.newExperienceStepTwo.getValue();
  }

  get getExperienceThree(): any {
    return this.newExperienceStepThree.getValue();
  }

  get getExperienceFour(): any {
    return this.newExperienceStepFour.getValue();
  }

  get getExperienceFive(): any {
    return this.newExperienceStepFive.getValue();
  }

  get getExperienceSix(): any {
    return this.newExperienceStepSix.getValue();
  }

  get getExperienceSeven(): any {
    return this.newExperienceStepSeven.getValue();
  }
  
  get getTokenCode(): any {
    return this.tokenCode.getValue();
  }

  get getCalculatePrice(): any {
    return this.calculatePrice.getValue();
  }

  get getIssuer(): any {
    return this.issuer.getValue();
  }

  getProfileStore(): any {
    return this.profileStore.asObservable();
  }

}
