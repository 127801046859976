export const MenuSidebar = [
    {
        route: '/new-experience',
        label: 'Nuevo momento',
        icon: 'assets/svg/Icon_nuevo.svg',
        icon_selected: 'assets/svg/Icon_nuevo_selected.svg',
        selected: false
    },
    {
        route: '/moments',
        label: 'Momentos',
        icon: 'assets/svg/Icon_propiedades.svg',
        icon_selected: 'assets/svg/Icon_propiedades_selected.svg',
        selected: false
    },
    {
        route: '/reserves-owner',
        label: 'Reservas',
        icon: 'assets/svg/Icon_reservaciones.svg',
        icon_selected: 'assets/svg/Icon_reservaciones_selected.svg',
        selected: false
    },
    {
        route: '/chat',
        label: 'Mensajes',
        icon: 'assets/svg/Icon_mensajes.svg',
        icon_selected: 'assets/svg/Icon_mensajes_selected.svg',
        selected: false
    },
    {
        route: '/calendar',
        label: 'Calendario',
        icon: 'assets/svg/Icon_calendario.svg',
        icon_selected: 'assets/svg/Icon_calendario_selected.svg',
        selected: false
    },
    {
        route: '/support',
        label: 'Soporte',
        icon: 'assets/svg/Icon_soporte.svg',
        icon_selected: 'assets/svg/Icon_soporte_selected.svg',
        selected: false
    },
    {
        route: '/moments-logged',
        label: 'Modo W2M',
        icon: 'assets/svg/Icon_w2m.svg',
        icon_selected: 'assets/svg/Icon_w2m.svg',
        selected: false
    },
    {
        route: '/login',
        label: 'Cerrar sesión',
        icon: 'assets/svg/Icon_logout.svg',
        icon_selected: 'assets/svg/Icon_logout.svg',
        selected: false
    }
]