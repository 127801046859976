<!-- <nz-layout class="layout"> -->
  <!-- <nz-sider class="menu-sidebar bg-black-1 d-none d-md-block" nzCollapsible nzWidth="264px" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <div class="d-flex al-center sp-center logo-w2m px-3">
          <div class="content-img pb-3 w-100 text-center">
            <img src="assets/images/wertumeet.png" style="max-width: 168px;" class="w-100" alt="">
          </div>
        </div>
        <ul nz-menu nzTheme="light" nzMode="inline" nzInlineCollapsed="true" class="bg-black-1">
          <li routerLink="/new-experience">
            <img [src]="!urlActive.indexOf('/new-experience') ? 'assets/svg/new-experience.svg' : 'assets/svg/new-experience-selected.svg'" alt="new-experience.svg">
            <span class="fs-6 fw-medium">Publicar</span>
          </li>
          <li routerLink="/moments" >
            <img [src]="!urlActive.indexOf('/moments') ? 'assets/svg/experience-selected.svg' : 'assets/svg/experience.svg'" alt="new-experience.svg">
            <span class="fs-6 fw-medium">Momentos</span>
          </li>
          <li routerLink="/reserves-owner">
            <img [src]="!urlActive.indexOf('/reserves') ? 'assets/svg/reserves-selected.svg' : 'assets/svg/reserves.svg'" alt="new-experience.svg">
            <span class="fs-6 fw-medium">Reservas</span>
          </li>
          <li routerLink="/chat">
            <img [src]="!urlActive.indexOf('/chat') ? 'assets/svg/messages-selected.svg' : 'assets/svg/messages.svg'" alt="messages.svg">
            <span class="fs-6 fw-medium">Mensajes</span>
          </li>
          <li routerLink="/calendar">
            <img [src]="!urlActive.indexOf('/calendar') ? 'assets/svg/calendar-selected.svg' : 'assets/svg/calendar.svg'" alt="new-experience.svg">
            <span class="fs-6 fw-medium">Calendario</span>
          </li>
          <li routerLink="/support">
            <img src="assets/svg/soporte.svg" alt="soporte.svg">
            <span class="fs-6 fw-medium">Soporte</span>
          </li>
          <li nz-menu-item routerLink="/">
            <img src="assets/svg/w2m.svg" alt="soporte.svg">
            <span class="fs-6 fw-medium">Modo W2M</span>
          </li>
          <li (click)="logout()">
            <img src="assets/svg/logout.svg" alt="soporte.svg">
            <span class="fs-6 fw-medium">Cerrar sesión</span>
          </li>
        </ul>
        <div class="account ps-4">
          <div class="d-flex al-center pointer w-100" style="gap: 5px;" routerLink="/profile-owner">
            <img src="assets/images/profile.png" alt="profile.png">
            <div class="d-flex flex-column">
              <span class="fs-6 pointer">{{usuario?.nombres.split(' ')[0]}}</span>
              <span class="fs-6 pointer">{{usuario?.apellidos.split(' ')[0]}}</span>
            </div>
          </div>
        </div>
  </nz-sider>
  <nz-layout class="right-layout" id="box">
    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout> -->
<!-- </nz-layout> -->
<div class="d-flex h-100" style="position: relative;">
  <div class="bg-black content-menu d-flex flex-column sp-between py-5">
    <div class="d-flex al-center sp-center logo-w2m px-3">
      <div class="content-img pb-3 w-100 text-center">
        <img src="assets/svg/w2m-white.svg" routerLink="/moments-logged" width="161px" alt="" class="pointer">
      </div>
    </div>
    <div>
      <ul class="p-0 m-0">
        <li class="pointer" *ngFor="let menu of menuSidebar" [ngClass]="menu.selected ? 'bg-white':''" (click)="activeRoute(menu)">
          <img [src]="menu.selected ? menu.icon_selected : menu.icon" alt="new-experience.svg">
          <span class="fs-6 fw-medium" [ngClass]="menu.selected ? '':'text-white'">{{menu.label}}</span>
        </li>
      </ul>
    </div>
    <div class="account ps-4">
      <div class="d-flex al-center pointer w-100" style="gap: 15px;" routerLink="/profile-owner">
        <!-- <img src="assets/images/profile.png" alt="profile.png"> -->
        <div class="photo d-flex al-center sp-center rounded-circle image-container" [ngStyle]="{'background-image': 'url(' + usuario?.foto_perfil + ')'}">
          <ng-container *ngIf="!usuario?.foto_perfil">
            <span class="fs-1 text-white">{{usuario?.nombres[0]}}</span>
          </ng-container>
        </div>
        <div class="d-flex flex-column">
          <span class="text-white fs-6 pointer">{{usuario?.nombres.split(' ')[0]}}</span>
          <span class="text-white fs-6 pointer">{{usuario?.apellidos.split(' ')[0]}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-container *ngIf="loader">
  <app-loader></app-loader>
</ng-container>


