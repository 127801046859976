<div class="d-flex flex-column h-100 content-information p-4 p-sm-5">
  <!-- <div [ngClass]="isPlatform ? 'content-view':''">
    <div [ngClass]=" isPlatform ? 'max-height':'' "> -->
      <div class="content-form" [ngClass]="isPlatform ? '':'m-auto'">
        <form [formGroup]="accountForm">
          <div class="d-flex flex-column sp-center content-account h-100">
            <div class="row">
              <div class="col-sm-12">
                <span class="fw-bold" [ngClass]="isPlatform ? 'fs-2':'fs-1'">Datos contables</span>
              </div>
            </div>
            <div>
              <div class="row mb-2">
                <div class="col-sm-12">
                  <span class="fw-regular title">Números de cuenta</span>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <span class="fw-regular title text-secondary">Al ingresar estos datos vamos a poder depositarte el dinero en retribución a tus servicios por la renta de tu espacio.</span>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-12">
                  <span class="title">Banco</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                    <nz-select class="w-100 fs-6 shadow-sm bg-white" nzPlaceHolder="Seleccione un banco" formControlName="bank" [ngClass]="{'disabled': accountData}">
                      <nz-option [nzValue]="bank.banco_id" [nzLabel]="bank.descripcion" *ngFor="let bank of banksList"></nz-option>
                    </nz-select>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-12">
                  <span class="title">Tipo de cuenta</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                    <nz-select class="w-100 border-0 fs-6 shadow-sm bg-white" nzPlaceHolder="Seleccione tipo de cuenta" formControlName="typeAccount" [ngClass]="{'disabled': accountData}">
                      <nz-option [nzValue]="account.id" [nzLabel]="account.descripcion" *ngFor="let account of accountsList"></nz-option>
                    </nz-select>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-12">
                  <span class="title">Número de cuenta</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <input nz-input formControlName="numberAccount" maxlength="13" placeholder="Ingresa número de cuenta" class="shadow-sm rounded fs-6 bg-white" 
                  (keypress)="keypressNumbers($event)" [ngClass]="{'disabled': accountData}"/>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-sm-12">
                  <span class="title">Número de cuenta interbancario ( CCI )</span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <input nz-input formControlName="cci" placeholder="Ingresa número de cuenta interbancario" maxlength="20" class="shadow-sm rounded fs-6 bg-white" 
                  (keypress)="keypressNumbers($event)" [ngClass]="{'disabled': accountData}"/>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-12">
                <button class="w-100 btn-medium border-0 animation rounded shadow-sm" [ngClass]="{'bg-black': isPlatform,'bg-orange': !isPlatform,'disabled': !validateForm()}"
                (click)="registerAccount()">
                  <span class="fs-4 text-white" *ngIf="!isLoading">{{ accountData ? 'Editar cuenta' : 'Guardar cambios' }}</span>
                  <span nz-icon [nzType]="'loading'" class="text-white fs-4" *ngIf="isLoading"></span>
                </button>
              </div>
            </div>
          </div>
        </form>  
      </div>
    <!-- </div>
  </div> -->
</div>
<ng-container *ngIf="loader">
  <app-loader></app-loader>
</ng-container>