<!-- <mat-dialog-content> -->
  <div class="p-5" style="max-height: 100vh">
    <div class="d-flex al-center sp-between">
      <span class="fw-bold fs-1">Detalles</span>
      <img src="assets/svg/close.svg" mat-dialog-close alt="">  
    </div>
    <div class="content-detail">
      <div class="row mt-3 mx-0">
        <div class="col-sm-12 d-flex">
          <div class="icon d-flex al-center sp-center p-3">
            <img [src]="'assets/svg/'+ reserve?.detail.linear" width="60px" height="60px"  alt="">
          </div>
          <div class="d-flex flex-column w-100 px-3">
            <div class="row">
              <div class="col-sm-12 d-flex sp-between al-center">
                <span class="fs-6 active">Experiencia {{reserve?.type == 1 ? 'en curso' : reserve?.type == 2 ? 'agendada' : 'terminada'}}</span>
              </div>
            </div>
            <hr class="my-2">
            <div class="row">
              <div class="col-sm-12">
                <span class="fs-3 fw-semibold">{{reserve?.detail.categoria}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-12 d-flex">
          <div class="icon d-flex al-center sp-center p-3">
            <img src="assets/svg/home.svg" width="60px" height="60px" alt="">
          </div>
          <div class="d-flex flex-column w-100 px-3">
            <div class="row">
              <div class="col-sm-12 d-flex sp-between al-center">
                <span class="fs-6">Espacio</span>
              </div>
            </div>
            <hr class="my-2">
            <div class="row">
              <div class="col-sm-12">
                <span class="fs-3 fw-semibold">{{reserve?.detail.tipo}}</span>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/location.svg" class="me-2" alt="">
                <span class="fs-6">{{reserve?.detail.direccion}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-12 d-flex">
          <div class="d-flex sp-center p-1">
            <div class="avatar rounded-circle">
            </div>
          </div>
          <div class="d-flex flex-column w-100 px-3">
            <div class="row">
              <div class="col-sm-12 d-flex sp-between al-center">
                <span class="fs-6">{{ isOwner ? 'Inquilino':'Propietario' }}</span>
              </div>
            </div>
            <hr class="my-2">
            <div class="row">
              <div class="col-sm-12">
                <span class="fs-3 fw-semibold">{{ isOwner ? reserve?.detail.nombres : reserve?.detail.propietario_nom }} 
                  {{ isOwner ? reserve?.detail.apellidos : reserve?.detail.propietario_ape }}</span>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/rate.svg" class="me-2" alt="">
                <span class="fs-6">Valoración promedio</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/identity.svg" class="me-2" alt="">
                <span class="fs-6">Identidad verificada</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/init-date.svg" class="me-2" alt="">
                <span class="fs-6">Registrado desde {{reserve?.detail.registrado}}</span>
              </div>
            </div>
            <!-- <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/home.svg" class="me-2" alt="">
                <span class="fs-6">Vive en Jr. La Condesa 123 - Miraflores - Perú</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-12 d-flex">
          <div class="icon d-flex al-center sp-center p-3">
            <img src="assets/svg/init-date.svg" width="60px" height="60px" alt="">
          </div>
          <div class="d-flex flex-column w-100 px-3">
            <div class="row">
              <div class="col-sm-12 d-flex sp-between al-center">
                <span class="fs-6">Detalles de la reservación</span>
              </div>
            </div>
            <hr class="my-2">
            <div class="row">
              <div class="col-sm-12">
                <span class="fs-3 fw-semibold">{{getDate(reserve?.detail.fecha_inicio)}}</span>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/hour.svg" class="me-2" alt="">
                <span class="fs-6">{{getHours(reserve?.detail.fecha_inicio, reserve?.detail.fecha_fin)}}</span>
                <span class="fs-6 fw-regular text-purple">{{isPostDay ? '(*Del día siguiente)':''}}</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/people.svg" class="me-2" alt="">
                <span class="fs-6">{{ reserve?.detail.aforo }} asistentes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-12 d-flex">
          <div class="icon d-flex al-center sp-center p-3">
            <img src="assets/svg/invoice.svg" width="60px" height="60px" alt="">
          </div>
          <div class="d-flex flex-column w-100 px-3">
            <div class="row">
              <div class="col-sm-12 d-flex sp-between al-center">
                <span class="fs-6">Detalles de facturación</span>
              </div>
            </div>
            <hr class="my-2">
            <div class="row">
              <div class="col-sm-12">
                <ng-container *ngIf="isOwner">
                  <span class="fs-3 fw-semibold">Monto que recibirá el propietario S/ {{ reserve.detail.total_propietario }}</span>
                </ng-container>
                <ng-container *ngIf="!isOwner">
                  <span class="fs-3 fw-semibold">Precio total del momento S/ {{reserve.detail.total_con_garantia}}</span>
                </ng-container>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/buy.svg" class="me-2" alt="">
                <span class="fs-6">El inquilino pagará por {{reserve.detail.horas}} Hrs de experiencia ---------S/ {{reserve.detail.precio_total}}</span>
              </div>
            </div>
            <ng-container *ngIf="!isOwner">
              <div class="row mt-3">
                <div class="col-sm-12 d-flex al-center">
                  <img src="assets/svg/clean.svg" class="me-2" alt="">
                  <span class="fs-6">Garantía ----------------------------------------S/ {{reserve.detail.garantia}}</span>
                </div>
              </div>
            </ng-container>
            <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/service.svg" class="me-2" alt="">
                <span class="fs-6">Impuesto ---------------------------------------S/ {{ isOwner ? reserve.detail.impuesto_propietario : reserve.detail.impuesto}}</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/service.svg" class="me-2" alt="">
                <span class="fs-6">Tarifa por servicio --------------------------------S/ {{ isOwner ? reserve.detail.fee_w2m_propietario : reserve.detail.fee_w2m}}</span>
              </div>
            </div>
            <ng-container *ngIf="!isOwner">
              <div class="row mt-3">
                <div class="col-sm-12 d-flex al-center">
                  <img src="assets/svg/amount-total.svg" class="me-2" alt="">
                  <span class="fs-6">Monto total por la experiencia ---------------------S/ {{ reserve.detail.total_con_garantia }}</span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isOwner">
              <div class="row mt-3">
                <div class="col-sm-12">
                  <hr>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 d-flex al-center">
                  <img src="assets/svg/buy.svg" class="me-2" alt="">
                  <span class="fs-6">El propietario recibirá --------------------------S/ {{ reserve.detail.total_propietario }}</span>
                </div>
              </div>
            </ng-container>
            <br><br>
            <!-- <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/clean.svg" class="me-2" alt="">
                <span class="fs-6">Servicio de limpieza ------------------------------S/ 30.00</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/service.svg" class="me-2" alt="">
                <span class="fs-6">Tarifa por servicio (30%) --------------------------S/ 84.00</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 d-flex al-center">
                <img src="assets/svg/amount-total.svg" class="me-2" alt="">
                <span class="fs-6">Monto total recibido por el propietario -------------S/ 834.00</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="row mt-5">
        <div class="col-sm-12 text-end">
          <button mat-dialog-close class="btn-dark">
            <span class="fw-bold fs-5">Cancelar reservación</span>
          </button>
          <button mat-dialog-close class="bg-white ms-4">
            <span class="fw-bold fs-5">Modificar reservación</span>
          </button>
        </div>
      </div> -->
    </div>
  </div>
<!-- </mat-dialog-content> -->
<!-- <div mat-dialog-actions align="end">
  
</div> -->