import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  user: any;
  profileForm: FormGroup;
  isLoading: false;
  file: File;
  imageUrl: any;
  loader: boolean;

  constructor(
    private storeservice: StateService,
    private commonservice: CommonService,
    private userservice: UserService,
    public dialogRef: MatDialogRef<UserEditComponent>
  ) { }

  ngOnInit() {
    this.storeservice.getProfileStore().subscribe(data => {
      this.user = data;
    });
    this.formBuilder();
    this.profileForm.patchValue(this.user);
  }

  formBuilder(){
    this.profileForm = new FormGroup({
      photo: new FormControl(''),
      presentacion: new FormControl('')
    });
  }

  updateProfile(){
    this.loader = true;
    const request = {
      "nombre": this.user.nombres,
      "apellido": this.user.apellidos,
      "celular": this.user.celular,
      "fec_nacimiento": this.user.fec_nacimiento,
      "pais_id": 1,
      "direccion": this.user.direccion,
      "presentacion": this.profileForm.value.presentacion
    }
    const message = 'Los datos de tu perfil han sido actualizados correctamente.'
    let file: File = null;
    if (this.imageUrl) {
      file = this.sendImageToService(this.imageUrl, request.nombre);
    }

    this.userservice.updateUser(request, file).subscribe(
      (response)=>{
        if (response.rtn) {
          this.dialogRef.close(true);
          this.commonservice.showAlert(message)
        }
      },
      ()=>{},
      ()=>{
        this.loader = false;
      }
    )
  }

  sendImageToService(compressedDataUrl, name: string): File {
    const blob = this.dataUrlToBlob(compressedDataUrl);
    const file = this.blobToFile(blob, `profile_${name}.jpg`); // Le das un nombre al archivo
    return file;
  }

  dataUrlToBlob(dataUrl: string): Blob {
    const byteString = atob(dataUrl.split(',')[1]); // Decodifica la parte base64
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]; // Obtiene el tipo MIME
  
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([ab], { type: mimeString });
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob], fileName, { type: theBlob.type });
  }

  uploadFile(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.compressImage(file, 100); // 100 KB
    }
  }

  compressImage(file: File, maxSizeKB: number) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event: any) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const MAX_WIDTH = 800; // Puedes ajustar el tamaño deseado
        const scaleSize = MAX_WIDTH / img.width;
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleSize;

        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

        let quality = 0.9;
        let compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
        
        while (this.dataUrlToFileSize(compressedDataUrl) > maxSizeKB * 1024 && quality > 0.1) {
          quality -= 0.1;
          compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
        }

        this.imageUrl = compressedDataUrl;
      };
    };
  }

  dataUrlToFileSize(dataUrl: string): number {
    const stringLength = dataUrl.length - 'data:image/jpeg;base64,'.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3);
    return sizeInBytes;
  }

}
