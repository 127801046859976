<div class="d-flex flex-column p-5" [class.content-modal]="!data.location" [class.location]="data.location">
  <div>
    <div class="mb-4 text-end">
      <img src="assets/svg/close.svg" mat-dialog-close alt="">
    </div>
    <div class="text-center mb-4">
      <span class="fs-4 fw-bold">{{data?.title ? data?.title : '¡Proceso realizado con éxito!'}}</span>
    </div>
    <div class="text-center content-message" [style.max-width]="data.maxWidthMessage ? data.maxWidthMessage : '360px'">
      <span [innerHTML]="data?.message"></span>
    </div>
    <ng-container *ngIf="data.location">
      <div class="text-center mt-3">
        <img src="assets/images/location.png" width="350" alt="location.png">
      </div>
    </ng-container>
  </div>
  <div>
    <ng-container *ngIf="!data?.action">
      <button class="bg-black w-100 d-flex al-center sp-center rounded" mat-dialog-close>
        <span class="text-white">Cerrar</span>
      </button>
    </ng-container>
    <ng-container *ngIf="data?.action">
      <button class="bg-black w-100 d-flex al-center sp-center rounded" (click)="confirmDialog()">
        <span class="text-white">{{ data?.action }}</span>
      </button>
    </ng-container>
  </div>
</div>