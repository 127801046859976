import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() commentList: any[]=[];
  @Input() isDetail: boolean = false;
  constructor(
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
  }

  getDateFormat(date){
    const monthName = this.datepipe.transform(date, 'MMMM');
    return monthName + ' del ' + new Date(date).getFullYear();
  }

}
