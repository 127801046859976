<div class="content-footer bg-green-4 d-flex al-center px-4 px-md-5">
  <div class="footer-data w-100 m-auto">
    <div class="row w-100">
      <div class="col-sm-6 col-md-3 logo mb-xl-0 mb-md-3">
        <img src="assets/svg/w2m-footer.svg" routerLink="/" class="pointer w-100" alt="">
      </div>
      <div class="col-sm-6 col-md-3 mb-xl-0 mb-md-3 mt-3 mt-sm-0">
        <div class="d-flex flex-column">
          <span class="text-white fw-semibold mb-3 fs-5">Llámanos</span>
          <div>
            <a href="tel:+51992912565" class="text-white fs-6 tel">+51 992912565</a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 mb-xl-0 mb-md-3 mt-3 mt-sm-0">
        <div class="d-flex flex-column">
          <span class="text-white fw-semibold mb-3 fs-5">Escríbenos</span>
          <div>
            <a href="mailto:soporte@wertumit.com" class="text-white fs-6 tel">soporte@wertumit.com</a>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-3 mb-xl-0 mb-md-3 mt-3 mt-sm-0">
        <div class="d-flex flex-column">
          <span class="text-white fw-semibold mb-3 fs-5">Documentos</span>
          <div>
            <a class="text-white fs-6 tel" target="_blank" href="https://develop.wertumit.com/files/TÉRMINOS%20Y%20CONDICONES%20W2M.pdf" >Términos y condiciones</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>