import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'airbnb';
  inputValue: string = '';
  selectedOption: string = '';
  onInputFocus() {
    // Lógica cuando el input obtiene el foco
  }

  onInputBlur() {
    // Lógica cuando el input pierde el foco
  }

}

