import { Component, Input, OnInit } from '@angular/core';
import { IAmountCalculate } from 'src/app/interfaces/amount-calculate';

@Component({
  selector: 'app-amount-calculate',
  templateUrl: './amount-calculate.component.html',
  styleUrls: ['./amount-calculate.component.scss']
})
export class AmountCalculateComponent implements OnInit {
  @Input() amountCalculate: IAmountCalculate;

  constructor() { }

  ngOnInit() {
  }

}
