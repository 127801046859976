<div>
  <ng-container *ngIf="!isDetail">
    <br>
    <div>
      <span class="title fw-bold">Comentarios</span>
    </div>
    <br>
  </ng-container>
  <div class="row al-start content-list-comments sp-between">
    <div *ngFor="let comment of commentList" class="col-md-12 col-xl-6" [ngClass]="isDetail ? ['content-comment-detail','mb-5']
    :['content-comment','mb-4']">
      <div class="d-flex al-center mb-3">
        <div class="photo me-3 rounded-circle d-flex al-center sp-center" [ngClass]="isDetail ? 'large':'small'">
          <span class="fs-2 fw-regular">{{comment?.nombres[0]}}</span>
        </div>
        <div>
          <div>
            <span class="fw-regular" [ngClass]="isDetail ? 'fs-5':'fs-6'">{{comment?.nombres}} {{comment?.apellidos}}</span>
          </div>
          <div>
            <span class="text-secondary" [ngClass]="isDetail ? 'fs-5':'fs-6'">{{getDateFormat(comment?.registrado)}}</span>
          </div>
        </div>
      </div>
      <div>
        <span class="fw-light" [ngClass]="isDetail ? 'fs-5':'fs-6'">{{comment?.comentario}}</span>
      </div>
    </div>
  </div>
</div>