import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../enums/endpoints.enum';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private httpService: HttpService, private httpClient: HttpClient) { }

  getCalendar(typeId, date){
    const url = environment.apiUrl + Endpoints.GET_CALENDAR + `${typeId}/${date}`;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getCalendarByMoment(tipo_busqueda, fecha, momento_id){
    const url = `${environment.apiUrl + Endpoints.GET_CALENDAR_MOMENT}${tipo_busqueda}/${fecha}/${momento_id}`;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }
  
  consultPriceEnd(momento_id, cant_horas, cant_asistentes){
    const url = `${environment.apiUrl + Endpoints.GET_CALCULATE_MOMENT}${momento_id}/${cant_horas}/${cant_asistentes}`;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

}
