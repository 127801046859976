import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { RegisterService } from 'src/app/services/registro.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {
  @Input() isPlatform : boolean = false;
  @Output() changeStep = new EventEmitter<number>();
  loader: boolean = false;
  accountForm: FormGroup;
  isLoading: boolean = false;
  banksList: any[]=[];
  accountsList: any[]=[];
  accountData: boolean = false;
  usuario: any;
  
  constructor(
    private registerservice: RegisterService,
    private storeservice: StateService,
    private commonservice: CommonService,
    private userservice: UserService
  ) { }

  ngOnInit() {
    this.storeservice.getProfileStore().subscribe(data => {
      this.usuario = data;
    });
    this.formBuilder();
    this.getBanks();
    this.getAccountTypes();
    if (this.usuario.contabilidad.length > 0) {
      this.accountData = true;
      const data = {
        bank: this.usuario.contabilidad[0].banco_id,
        typeAccount: this.usuario.contabilidad[0].tipo_cuenta_bancaria_id,
        numberAccount: this.usuario.contabilidad[0].numero_cuenta,
        cci: this.usuario.contabilidad[0].numero_cuenta_inter
      }
      this.accountForm.patchValue(data)
    }
    // this.validateAccountData();
  }

  validateAccountData(){
    if (this.usuario.contabilidad.length > 0) {
      this.changeStep.emit(2);
    }
  }

  formBuilder(){
    this.accountForm = new FormGroup(
      {
        bank: new FormControl('', Validators.required),
        typeAccount: new FormControl('', Validators.required),
        numberAccount: new FormControl('', Validators.required),
        cci: new FormControl('', Validators.required)
      }
    );
  }

  getBanks(){
    this.registerservice.getBanks().subscribe(
      (response)=>{
        if (response.rtn) {
          this.banksList = response.result;
        }
      }
    );
  }

  getAccountTypes(){
    this.registerservice.getAccountTypes().subscribe(
      (response)=>{
        if (response.rtn) {
          this.accountsList = response.result;
        }
      }
    );
  }

  registerAccount(){
    if (!this.accountData) {
      this.loader = true;
      const request = {
        "cuenta_id": this.usuario.contabilidad.length > 0 ? this.usuario.contabilidad[0].cuenta_id : 0, //Depende de este campo si agrega o actualiza. 0: Agregar
        "banco_id": +this.accountForm.value.bank,
        "tipo_cuenta_id": +this.accountForm.value.typeAccount,
        "numero_cuenta":this.accountForm.value.numberAccount,
        "numero_cuenta_inter": this.accountForm.value.cci  
      }
      const message = 'Los datos contables han sido guardados correctamente.'
      this.registerservice.registerAccount(request).subscribe(
        (response)=>{
          this.loader = false;
          if (response.rtn) {
            this.accountData = true;
            this.getProfile(message);
          }
        }
      );
    }else {
      this.accountData = false;
    }
  }

  getProfile(message){
    this.userservice.getUser(1).subscribe(
      (response)=>{
        this.isLoading = false;
        if (response.rtn) {
          if (this.usuario.contabilidad.length > 0) {
            this.commonservice.showAlert(message)
          }else{
            this.changeStep.emit(2);
          }
          this.storeservice.profileStore.next(response.result);
        }
      }
    );
  }

  keypressNumbers(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  validateForm(): boolean {
    if (!this.accountForm.valid) {
      return
    }
    return true;
  }

}
