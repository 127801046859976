export enum Endpoints  {
    SEND_EMAIL = 'seguridad/auth_email',
    VALIDATE_CODE = 'seguridad/valida_codigo',
    REGISTER = 'usuario/registro',
    LOGIN = 'seguridad/acceder',
    LOGOUT = 'seguridad/cerrar_acceso',
    GET_USER = 'usuario/perfil',
    UPDATE_USER = 'usuario/actualizar',
    CHANGE_PASSWORD = 'usuario/contrasenia',
    GET_EXPERIENCES = 'propiedad/experiencias',
    GET_TYPE_PROPERTIES = 'experiencia/tipo_propiedad',
    GET_CATEGORIES = 'experiencia/categorias',
    GET_SUBCATEGORIES = 'experiencia/subcategorias',
    GET_FEATURES = 'experiencia/caracteristicas',
    REGISTER_EXPERIENCE = 'propiedad/registrar',
    GET_PROPERTIE = 'propiedad/obtener/',
    GET_PROPERTIES = 'experiencia/tipo_propiedad',
    GET_USERS = 'reserva-mensaje/usuarios-no-reserva/',
    GET_USERS_GLOBAL = 'reserva-mensaje/usuarios/',
    GET_USER_MESSAGES = 'reserva-mensaje/conversacion/',
    GET_CALENDAR = 'reserva/calendario/',
    GET_CALENDAR_MOMENT = 'reserva/calendario-momento/',
    GET_MOMENTS = 'propiedad/momentos',
    GET_MOMENT = 'propiedad/momento-principal/',
    SAVE_FAVORITE = 'propiedad/momento-favorito',
    GET_FAVORITES = 'propiedad/favoritos',
    CONTACT_OWNER = 'reserva/conversa-propietario',
    GET_CALCULATE_MOMENT = 'propiedad/momento-calculo/',
    SEND_PRERESERVE = 'reserva/rentar-momento',
    PAY_RESERVE = 'reserva/pagar-momento',
    SEND_MESSAGE = 'reserva-mensaje/conversacion',
    SEND_PAYMENT = 'reserva/procesar-pago',
    GET_RESERVES = 'reserva/listar/',
    GET_MESSAGES_SUPPORT = 'soporte/mensajes/',
    SEND_MESSAGE_TO_SUPPORT = 'soporte/mensaje',
    GET_BANKS = 'usuario-contabilidad/listar-bancos',
    GET_ACCOUNTS_TYPES = 'usuario-contabilidad/listar-tipos-cuenta',
    REGISTER_ACCOUNT = 'usuario-contabilidad/modificar',
    CHANGE_PASSWORD_RECOVERY = 'seguridad/cambiar_contrasenia',
    SEND_EMAIL_RECOVERY = 'seguridad/codigo_contrasenia',
    GET_QUIZ = 'valoracion/preguntas/',
    VALORATION_USER = 'valoracion/usuario',
    VALORATION_MOMENT = 'valoracion/momento',
    GET_FINANCE = 'propiedad/finanzas/',
    GET_FILTER_MOMENTS = 'propiedad/momentos/',
    APPROBE_REQUEST = 'reserva/cambiar-estado',
    GET_DEPARTMENTS = 'ubigeo/departamentos',
    GET_PROVINCES = 'ubigeo/provincias',
    GET_DISTRICTS = 'ubigeo/distritos',
    CHANGE_ACTIVE = 'propiedad/activar'
}