import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../enums/endpoints.enum';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private httpService: HttpService, private httpClient: HttpClient) { }

  sendEmail(request) {
    const url = environment.apiUrl + Endpoints.SEND_EMAIL;
    const options = this.httpService.headerOptionsJson(false, false);
    return this.httpClient.post<any>(url, request, options);
  }

  getUsers(tipoUsuario){
    const url = environment.apiUrl + Endpoints.GET_USERS + tipoUsuario;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getMessages(reserva_id){
    const url = environment.apiUrl + Endpoints.GET_USER_MESSAGES + reserva_id;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  sendMessage(request){
    const url = environment.apiUrl + Endpoints.SEND_MESSAGE;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.post<any>(url, request, options);
  }

  getMessagesSupport(userId){
    const url = environment.apiUrl + Endpoints.GET_MESSAGES_SUPPORT + userId;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  registerMessageToSupport(request){
    const url = environment.apiUrl + Endpoints.SEND_MESSAGE_TO_SUPPORT;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.post<any>(url, request, options);
  }

}
