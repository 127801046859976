import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../enums/endpoints.enum';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private httpService: HttpService, private httpClient: HttpClient) { }

  sendEmail(request) {
    const url = environment.apiUrl + Endpoints.SEND_EMAIL;
    const options = this.httpService.headerOptionsJson(false, false);
    return this.httpClient.post<any>(url, request, options);
  }

  validateCode(request, token) {
    const url = environment.apiUrl + Endpoints.VALIDATE_CODE;
    const options = this.httpService.headerOptionsTokenCode(token, false);
    return this.httpClient.post<any>(url, request, options);
  }

  registerUser(request, token){
    const url = environment.apiUrl + Endpoints.REGISTER;
    const options = this.httpService.headerOptionsTokenCode(token, false);
    return this.httpClient.post<any>(url, request, options);
  }

  login(request) {
    const url = environment.apiUrl + Endpoints.LOGIN;
    const options = this.httpService.headerOptionsJson(false, false);
    return this.httpClient.post<any>(url, request, options);
  }

  logout(request) {
    const url = environment.apiUrl + Endpoints.LOGOUT;
    const options = this.httpService.headerOptionsJson(false, false);
    return this.httpClient.post<any>(url, request, options);
  }

  getBanks() {
    const url = environment.apiUrl + Endpoints.GET_BANKS;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getAccountTypes() {
    const url = environment.apiUrl + Endpoints.GET_ACCOUNTS_TYPES;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  registerAccount(request){
    const url = environment.apiUrl + Endpoints.REGISTER_ACCOUNT;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

}
