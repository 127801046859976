import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http'


@Injectable({
	providedIn: 'root'
})
export class HttpService {

	constructor() {

	}

	sendForm( data:object, listFiles: Array<any>):FormData{
		var formData = new FormData();
		if(data != null){
			let key:keyof object;
			for (const [k, v] of Object.entries(data)) {
				if (k == "categoria" || k == "caracteristica" || k == "eliminados") {
					formData.append(k,JSON.stringify(v))
				}else{
					formData.append(k,v)
				}
			}
		}

		for (let i = 0; i < listFiles.length; i++) {
			for (let j = 0; j < listFiles[i].files.length; j++) {
				formData.append(listFiles[i].categoria_id+'[]',listFiles[i].files[j])
			}
		}

		// if(file != null){
		// 	formData.append('photo',file,file.name)
		// }
		
		return formData;
	}

	sendFormProfile( data:object, file: File):FormData{
		var formData = new FormData();
		if(data != null){
			let key:keyof object;
			for (const [k, v] of Object.entries(data)) {
				formData.append(k,v)
			}
		}
		if(file != null){
			formData.append('file',file,file.name)
		}
		return formData;
	}

	headerOptionsJson(sendToken:boolean, isGet:boolean, filters?):object {

		let httpOptions:object;
		let headers = new HttpHeaders();
		let params = new HttpParams();

		if (sendToken) {
			let token = localStorage.getItem("JWT");
			if (token != null ) headers = headers.set("token",token);
		}

		if (!isGet) {
			headers = headers.set("Content-Type", "application/json; charset=utf-8");
		}
		
		httpOptions = { headers: headers, params :  filters};

		return httpOptions;
	}

	headerOptionsTokenCode(token:string, isGet:boolean):object {

		let httpOptions:object;
		let headers = new HttpHeaders();
		let params = new HttpParams();

		if (token) {
			if (token != null ) headers = headers.set("token", token);
		}

		if (!isGet) {
			headers = headers.set("Content-Type", "application/json; charset=utf-8");
		}
		
		httpOptions = { headers: headers};

		return httpOptions;
	}

	headerOptionsRefreshJson(sendToken:boolean, isGet:boolean):object {

		let httpOptions:object;
		let headers = new HttpHeaders();

		if (sendToken) {
			let token = localStorage.getItem("refresh_token");
			if (token != null ) headers = headers.set("Authorization", "Bearer " +token);
		}
		
		if (!isGet) {
			headers = headers.set("Content-Type", "application/json; charset=utf-8");
		}
		
		httpOptions = { headers: headers };

		return httpOptions;
	}

	headerOptionsUrlenconde(sendToken:boolean, isGet:boolean):object {

		let httpOptions:object;
		let headers = new HttpHeaders();

		if (sendToken) {
			let token = localStorage.getItem("JWT");
			if (token != null ) headers = headers.set("Authorization", "Bearer " +token);
		}
		
		if (!isGet) {
			headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
		}
		
		httpOptions = { headers: headers };

		return httpOptions;
	}


	headerOptionsForm(sendToken:boolean):object {
		let httpOptions:object;
		
		let headers = new HttpHeaders();

		if (sendToken) {
			let token = localStorage.getItem("JWT");
			if (token != null ) headers = headers.set("token",token);
		}
		
		httpOptions = { headers: headers };
		return httpOptions;
	}

}