<div class="p-5 d-flex flex-column h-100">
  <div>
    <div class="text-end">
      <img src="assets/svg/close.svg" mat-dialog-close class="pointer" alt="">
    </div>
    <div>
      <span class="fs-1 fw-bold">Valoración {{data?.owner ? 'inquilino':'propietario'}}</span>
    </div>
    <div class="mb-3">
      <span class="fw-regular fs-6">Responde la siguiente encuesta sobre el comportamiento del {{data?.owner ?
        'inquilino':'propietario'}} al <br>rentar un momento contigo.</span>
    </div>
    <div class="content-type mb-2">
      <span class="fw-semibold fs-6">{{data?.owner ? 'Inquilino':'Propietario'}}</span>
    </div>
    <div class="mb-3">
      <ng-container *ngIf="data?.owner">
        <span>{{data?.reserve.nombres}} {{data?.reserve.apellidos}}</span>
      </ng-container>
      <ng-container *ngIf="!data?.owner">
        <span class="fs-6 fw-regular">{{data?.reserve.propietario_nom}} {{data?.reserve.propietario_ape}}</span>
      </ng-container>
    </div>
  </div>
  <div>
    <div class="content-type mb-2">
      <span class="fw-semibold fs-6">Encuesta</span>
    </div>
    <div class="mb-3">
      <span class="text-secondary fs-6 fw-regular">En una escala del 1 al 5, donde 1 es el menor puntaje y 5 es el mayor
        puntaje, califique según su apreciación:</span>
    </div>
    <div class="mb-5">
      <div *ngFor="let quiz of quizList; let i = index">
        <div class="mb-3">
          <div class="mb-3">
            <span>{{i+1}}. {{quiz.pregunta}}</span>
          </div>
          <div class="d-flex content-rates">
            <div class="rate rounded-circle d-flex al-center sp-center pointer animation"
              [ngClass]="i+1 == quiz.rate ? 'bg-black':''" *ngFor="let option of [1,1,1,1,1]; let i = index"
              (click)="quiz.rate = i+1">
              <span class="fs-6 fw-semibold" [ngClass]="i+1 == quiz.rate ? 'text-white':''">{{i+1}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="mb-3">
          <span>5. {{data?.owner ? 'Recomendaría a '+ data?.reserve.nombres + ' ' + data?.reserve.apellidos +' para una
            próxima reserva'
            :'Recomendaría este espacio para otras actividades.'}}</span>
        </div>
        <div class="d-flex content-rates">
          <div class="rate rounded-circle d-flex al-center sp-center pointer animation"
            [ngClass]="i == recommended ? 'bg-black':''" *ngFor="let option of [1,1]; let i = index"
            (click)="recommended = i">
            <span class="fs-6 fw-semibold" [ngClass]="i == recommended ? 'text-white':''">{{i == 0 ? 'No':'Si'}}</span>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <span>6. {{data?.owner ? 'Escriba un mensaje sobre '+ data?.reserve.nombres + ' ' + data?.reserve.apellidos
          :'Escriba un mensaje sobre este espacio.'}}</span>
      </div>
      <div>
        <textarea rows="4" placeholder="Escribe aquí" [(ngModel)]="comment" maxlength="250" nz-input
          class="rounded fs-6 shadow-sm"></textarea>
      </div>
    </div>
  </div>
  <div class="d-flex sp-end pb-5">
    <button class="bg-black d-flex al-center sp-center rounded w-100" (click)="sendValoration()" [ngClass]="validateQuiz() ? 'disabled':''">
      <span class="text-white fs-5 fw-medium">Enviar valoración</span>
    </button>
  </div>
</div>