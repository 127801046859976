import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit {
  hide: boolean;
  hideRe: boolean;
  hideAc: boolean;
  @Input() isPlatform : boolean = false;
  passwordForm: FormGroup;
  passwordWrong: boolean = false;
  token: string;
  loader: boolean = false;
  usuario: any;

  constructor(
    private userservice: UserService,
    private storeservice: StateService,
    private commonservice: CommonService,
    private router: Router,
    private activeroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.storeservice.getProfileStore().subscribe(data => {
      this.usuario = data;
    });
    this.formBuilder();
    this.activeroute.queryParams.subscribe(params => {
      const token = params['auth'];
      this.token = token;
      if (token) {
        this.passwordForm.controls.passwordCurrent.clearValidators();
      }
    });
  }

  formBuilder(){
    this.passwordForm = new FormGroup({
      passwordCurrent: new FormControl(''),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$&*+-.=?^_`{|}~()])/)]),
      repeatPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[A-Z])(?=.*[!@#$&*+-.=?^_`{|}~()])/)]),
    })
  }

  changePassword(){
    this.passwordWrong = false;
    if (this.passwordForm.value.newPassword != this.passwordForm.value.repeatPassword) {
      this.passwordWrong = true;
      return
    }

    const request = {
      "contrasenia": this.passwordForm.value.newPassword
    }

    this.loader = true;
    if (this.usuario) {
      const message = 'La nueva contraseña ha sido actualizada correctamente.'
      this.commonservice.showAlert(message)
      this.userservice.changePassword(request).subscribe(
        (response)=>{
          if (response.rtn) {
            this.commonservice.showAlert(message)
          }
        },
        () => {},
        () => {
          this.loader = false;
        }
      )
    }else{
      const message = 'La nueva contraseña ha sido actualizada correctamente. Por favor, inicie sesión.'
      this.userservice.changePasswordRecovery(request,this.token).subscribe(
        (response)=>{
          if (response.rtn) {
            this.commonservice.showAlert(message);
            this.router.navigateByUrl('/login')
          }
        },
        () => {},
        () => {
          this.loader = false;
        }
      )
    }
  }

  validateForm():boolean {
    if (!this.passwordForm.valid) {
      return;
    }
    if (this.passwordForm.value.newPassword != this.passwordForm.value.repeatPassword) {
      return;
    }
    return true;
  }

}
