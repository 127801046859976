import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../enums/endpoints.enum';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpService: HttpService, private httpClient: HttpClient) { }

  getUser(tipo) {
    const url = environment.apiUrl + Endpoints.GET_USER + `/${tipo}`;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  updateUser(request, file?: File) {
    const url = environment.apiUrl + Endpoints.UPDATE_USER;
    let formData: FormData = this.httpService.sendFormProfile(request, file);
    const options = this.httpService.headerOptionsForm(true);
    return this.httpClient.post<any>(url, formData, options);
  }

  changePassword(request) {
    const url = environment.apiUrl + Endpoints.CHANGE_PASSWORD;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  changePasswordRecovery(request, token) {
    const url = environment.apiUrl + Endpoints.CHANGE_PASSWORD_RECOVERY;
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    headers = headers.set("token",token);
    const options = { headers: headers};
    return this.httpClient.post<any>(url, request, options);
  }

  login(request){
    const url = environment.apiUrl + Endpoints.LOGIN;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  logout(){
    const url = environment.apiUrl + Endpoints.LOGOUT;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, null, options);
  }

  sendEmail(request){
    const url = environment.apiUrl + Endpoints.SEND_EMAIL_RECOVERY;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

}
