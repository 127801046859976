export const Months = [
    {
        id: '01',
        value: 'Ene.',
        value_large: 'Enero'
    },
    {
        id: '02',
        value: 'Feb.',
        value_large: 'Febrero'
    },
    {
        id: '03',
        value: 'Mar.',
        value_large: 'Marzo'
    },
    {
        id: '04',
        value: 'Abr.',
        value_large: 'Abril'
    },
    {
        id: '05',
        value: 'May.',
        value_large: 'Mayo'
    },
    {
        id: '06',
        value: 'Jun.',
        value_large: 'Junio'
    },
    {
        id: '07',
        value: 'Jul.',
        value_large: 'Julio'
    },
    {
        id: '08',
        value: 'Ago.',
        value_large: 'Agosto'
    },
    {
        id: '09',
        value: 'Sep.',
        value_large: 'Septiembre'
    },
    {
        id: '10',
        value: 'Oct.',
        value_large: 'Octubre'
    },
    {
        id: '11',
        value: 'Nov.',
        value_large: 'Noviembre'
    },
    {
        id: '12',
        value: 'Dic.',
        value_large: 'Diciembre'
    }
]