import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './http.service';
import { RegisterService } from './registro.service';
import { UserService } from './user.service';
import { ExperienceService } from './experience.service';
import { StateService } from './state.service';
import { MessageService } from './message.service';
import { CalendarService } from './calendar.service';
import { CommonService } from './common.service';
import { MaterialModule } from '../shared/material/material.module';


@NgModule( {
  declarations: [],
  imports: [
    MaterialModule,
    HttpClientModule
  ],
  providers: [
    HttpService,
    RegisterService,
    UserService,
    ExperienceService,
    StateService,
    MessageService,
    CalendarService,
    CommonService
  ]
} )
export class ServicesModule {}
