import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ExperienceService } from 'src/app/services/experience.service';

@Component({
  selector: 'app-valoration',
  templateUrl: './valoration.component.html',
  styleUrls: ['./valoration.component.scss']
})
export class ValorationComponent implements OnInit {
  quizList: any[]=[];
  comment: string;
  recommended: number = -1;

  constructor(
    private experienceservice: ExperienceService,
    public dialogRef: MatDialogRef<ValorationComponent>,
    private commonservice: CommonService,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit() {
    this.getQuiz();
  }

  getQuiz(){
    const tipo = this.data?.owner ? 2 : 1;
    this.experienceservice.getQuiz(tipo).subscribe(
      (response)=>{
        if (response.rtn) {
          this.quizList = response.result;
          this.quizList.forEach((element)=>{
            element.rate = 0;
          });
        }
      }
    )
  }

  sendValoration(){
    let questions = [];
    this.quizList.forEach(element => {
      questions.push(
        {
          id: element.id,
          puntaje: element.rate
        }
      )
    });
    if (this.data?.owner) {
      const request = {
        reserva_id : this.data?.reserve.reserva_id,
        momento_id : this.data?.reserve.id,
        usuario_id : this.data?.reserve.cliente,
        recomendado : this.recommended,
        preguntas : questions,
        comentario : this.comment
      }
      this.experienceservice.sendValorationUser(request).subscribe(
        (response)=>{
          if (response.rtn) {
            this.dialogRef.close();
            const message = 'La valoración al inquilino se ha realizado correctamente.';
            this.commonservice.showAlert(message);
          }
        }
      )
    }else{
      const request = {
        reserva_id : this.data?.reserve.reserva_id,
        momento_id : this.data?.reserve.id,
        recomendado : this.recommended,
        preguntas : questions,
        comentario : this.comment
      }
      this.experienceservice.sendValorationMoment(request).subscribe(
        (response)=>{
          if (response.rtn) {
            this.dialogRef.close();
            const message = 'La valoración al propietario se ha realizado correctamente.';
            this.commonservice.showAlert(message);
          }
        }
      )
    }
  }

  validateQuiz(){
    let findQuizEmpty = this.quizList.find(element => element.rate == 0)
    return ((this.recommended == -1) || !this.comment || (findQuizEmpty)) ? true : false;
  }

}
