import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuSidebar } from 'src/app/enums/menu-sidebar.enum';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  isCollapsed : boolean = false;
  offsetTop = 10;
  urlActive: string;
  usuario: any;
  menuSidebar: any[]=[];
  loader: boolean = false;

  constructor(
    private router : Router,
    private storeservice: StateService,
    private userservice: UserService
    ) {}

  ngOnInit() {
    
    this.storeservice.getProfileStore().subscribe(data => {
      this.usuario = data;
    });
    let menuTemp = [...MenuSidebar];
    menuTemp.forEach((element)=>{
      element.selected = false;
    })
    this.menuSidebar = menuTemp;
    this.setMenu();
  }

  setMenu(){
    let find = this.menuSidebar.find((element)=>element.route == this.router.url);
    if (find) {
      find.selected = true;
    }
  }

  activeRoute(menu){
    this.menuSidebar.forEach((element)=>element.selected = false);
    menu.selected = true;
    if (menu.route != '/login') {
      this.router.navigateByUrl(menu.route);
    }else{
      this.logout();
    }
  }

  ngAfterContentInit(){
    this.urlActive = this.router.url;
  }

  logout(){
    this.loader = true;
    this.userservice.logout().subscribe(
      (response)=>{
        this.loader = false;
        if (response.rtn) {
          localStorage.clear();
          this.storeservice.profileStore.next(null);
          this.router.navigateByUrl('/login');
        }
      }
    );
  }

}
      