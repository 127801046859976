<div style="height: 100vh;" class="p-4">
  <div class="d-flex flex-column h-100 sp-center al-center">
    <div class="mb-5">
      <img src="assets/images/register-success.png" class="w-100" alt="register-success.png">
    </div>
    <div class="mb-4">
      <span class="fw-bold fs-2">Datos de cuenta de banco correctos</span>
    </div>
    <div class="mb-5 text-center content-description-success">
      <span class="fs-4 fw-light">Tus futuros pagos de parte de Wertumit llegarán al número de cuenta que nos compartiste.</span>
    </div>
    <button class="bg-orange btn-moment border-0 rounded" routerLink="/new-experience">
      <span class="fs-5 text-white">Publicar momento</span>
    </button>
  </div>
</div>