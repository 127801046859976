import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetailReserveComponent } from 'src/app/components/reserves/contents/detail-reserve/detail-reserve.component';
import { Months } from 'src/app/enums/months.enum';
import { ExperienceService } from 'src/app/services/experience.service';
import { ValorationComponent } from '../valoration/valoration.component';

@Component({
  selector: 'app-list-reserves',
  templateUrl: './list-reserves.component.html',
  styleUrls: ['./list-reserves.component.scss'],
})
export class ListReservesComponent implements OnInit {
  momentsCurrent: any[]=[];
  momentsCooming: any[]=[];
  momentsFinished: any[]=[];
  optionCurrent: number = 1;
  @Input() isOwner: boolean = false;
  loader: boolean = false;
  isPostDay: boolean;

  constructor(
    private dialog: MatDialog,
    private experienceservice: ExperienceService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.getReserves();
  }

  getReserves() {
    this.loader = true;
    const typeUser = this.isOwner ? 1 : 2;
    this.experienceservice.getReserves(typeUser).subscribe((response) => {
      this.loader = false;
      if (response.rtn) {
        this.momentsCurrent = response.result.en_curso;
        this.momentsCooming = response.result.proximo;
        this.momentsFinished = response.result.finalizado;
      }
    });
  }

  changeOption(number) {
    this.optionCurrent = number;
  }

  showDetail(reserve, optionCurrent) {
    this.dialog.open(DetailReserveComponent, {
      autoFocus: false,
      width: '100%',
      maxWidth: '986px',
      height: '85vh',
      data: {
        type: optionCurrent,
        detail: reserve
      }
    });
  }

  showQuiz(moment){
    this.dialog.open(ValorationComponent, {
      autoFocus: false,
      width: '100%',
      maxWidth: '986px',
      height: '85vh',
      disableClose: false,
      data: {
        owner : this.isOwner,
        reserve : moment
      }
    });
  }

  getDate(dateReserve){
    let transformDate = this.datepipe.transform(dateReserve,'dd/MM/yyyy')
    let date = transformDate.split('/');
    let month = Months.find((element)=>element.id == date[1])
    return date[0] + ' de '+ month.value +' del '+ date[2];
  }

  getHours(dateStart, dateEnd){
    let transformDateStart = this.datepipe.transform(dateStart,'hh:mm aa');
    let transformDateEnd = this.datepipe.transform(dateEnd,'hh:mm aa');
    this.isPostDay = new Date(dateEnd) > new Date(dateStart) ? true : false;
    return 'Desde las ' + transformDateStart + ' hasta  las '+ transformDateEnd;
  }

}
