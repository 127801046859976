import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogNewPropertyComponent } from 'src/app/components/dialogs/dialog-new-property/dialog-new-property.component';
import { ExperienceService } from 'src/app/services/experience.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss']
})
export class HeaderProfileComponent implements OnInit {
  usuario: any;
  isOwner: boolean = false;
  loader: boolean = false;

  constructor(
    private router: Router,
    private storeservice: StateService,
    private userservice: UserService,
    private experienceservice: ExperienceService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.storeservice.getProfileStore().subscribe(data => {
      this.usuario = data;
    });
    //this.isOwner = this.usuario.contabilidad.length > 0 ? true : false;
  }

  ngOnChanges(){
  }

  logout(){
    this.loader = true;
    this.userservice.logout().subscribe(
      (response)=>{
        this.loader = false;
        if (response.rtn) {
          localStorage.clear();
          this.storeservice.profileStore.next(null);
          this.router.navigateByUrl('/login');
        }
      }
    );
  }

  validateOwner(){
    this.loader = true;
    this.getMomentsOwner();
  }

  getMomentsOwner() {
    this.experienceservice.getExperiences().subscribe(
      (response)=>{
        response.result.length > 0 ? this.router.navigateByUrl('/new-experience') : this.router.navigateByUrl('/register-preview')
      },
      ()=>{},
      ()=>{
        this.loader = false
      }
    );
  }

  goTo(){
    if (this.usuario) {
      this.router.navigateByUrl('/moments-logged');
    }else{
      this.router.navigateByUrl('/');
    }
  }

}
