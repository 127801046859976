import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../shared/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private dialog: MatDialog
  ) { }

  showAlert(message:string, title?: string, location?: boolean) {
    this.dialog.open(AlertComponent,{
      width:'776px',
      data: {
        message: message,
        title: title,
        location: location
      }
    });
  }
  
}
