<div class="d-flex flex-column content-detail-price">
  <div class="d-flex sp-between fs-6 mb-1">
    <span>Precio x hora</span>
    <span class="fw-semibold">S/ {{ amountCalculate?.precio_base }}</span>
  </div>
  <div class="d-flex sp-between fs-6 mb-1">
    <span>Subtotal</span>
    <span class="fw-semibold">S/ {{ amountCalculate?.precio_total }}</span>
  </div>
  <div class="d-flex sp-between fs-6 mb-1">
    <span>Garantía</span>
    <span class="fw-semibold">S/ {{ amountCalculate?.garantia }}</span>
  </div>
  <div class="d-flex sp-between fs-6 mb-1">
    <span>Comisión x servicio</span>
    <span class="fw-semibold">S/ {{ amountCalculate?.fee_w2m }}</span>
  </div>
  <div class="d-flex sp-between fs-6">
    <span>Impuestos</span>
    <span class="fw-semibold">S/ {{ amountCalculate?.impuesto }}</span>
  </div>
</div>
<div class="d-flex flex-column content-detail-price mt-3">
  <div class="d-flex sp-between fs-6">
    <span>Total a pagar</span>
    <span class="fw-semibold">S/ {{ amountCalculate?.total_con_garantia }}</span>
  </div>
</div>