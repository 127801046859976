import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Endpoints } from '../enums/endpoints.enum';
import { HttpService } from './http.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExperienceService {
  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient
  ) {}

  getExperiences() {
    const url = environment.apiUrl + Endpoints.GET_EXPERIENCES;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getTypeProperties() {
    const url = environment.apiUrl + Endpoints.GET_TYPE_PROPERTIES;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getCategories() {
    const url = environment.apiUrl + Endpoints.GET_CATEGORIES;
    const options = this.httpService.headerOptionsJson(false, true);
    return this.httpClient.get<any>(url, options);
  }


  getSubcategoriesFilter() {
    const url = environment.apiUrl + Endpoints.GET_SUBCATEGORIES;
    const options = this.httpService.headerOptionsJson(false, true);
    return this.httpClient.get<any>(url, options);
  }

  getFeatures() {
    const url = environment.apiUrl + Endpoints.GET_FEATURES;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  registerExperience(request, listFiles) {
    let formData: FormData = this.httpService.sendForm(request, listFiles);
    const url = environment.apiUrl + Endpoints.REGISTER_EXPERIENCE;
    const options = this.httpService.headerOptionsForm(true);
    return this.httpClient.post<any>(url, formData, options);
  }

  getPropertie(propertieId: number) {
    const url = environment.apiUrl + Endpoints.GET_PROPERTIE + propertieId;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getMoments() {
    const url = environment.apiUrl + Endpoints.GET_MOMENTS;
    const options = this.httpService.headerOptionsJson(false, true);
    return this.httpClient.get<any>(url, options);
  }

  getMoment(id: number) {
    const url = environment.apiUrl + Endpoints.GET_MOMENT + id;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  saveFavorite(request){
    const url = environment.apiUrl + Endpoints.SAVE_FAVORITE;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  getFavorites(){
    const url = environment.apiUrl + Endpoints.GET_FAVORITES;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getProperties() {
    const url = environment.apiUrl + Endpoints.GET_PROPERTIES;
    const options = this.httpService.headerOptionsJson(true, true);
    return this.httpClient.get<any>(url, options);
  }

  getSubcategories() {
    let data = {
      rtn: true,
      result: [
        {
          id: 1,
          tipo: 'Productora',
          selected: false,
          categories: [
            {
              id: 1,
              value: 'Influencers',
              checked: false,
            },
            {
              id: 2,
              value: 'Ads Comerciales',
              checked: false,
            },
            {
              id: 3,
              value: 'Videoclip',
              checked: false,
            },
            {
              id: 4,
              value: 'Photoshoot',
              checked: false,
            },
          ],
        },
        {
          id: 2,
          tipo: 'Social',
          selected: false,
          categories: [
            {
              id: 1,
              value: 'Galeria de Arte',
              checked: false,
            },
            {
              id: 2,
              value: 'Showroom',
              checked: false,
            },
            {
              id: 3,
              value: 'Exposición / Muestra',
              checked: false,
            },
          ],
        },
        {
          id: 3,
          tipo: 'Corporativo',
          selected: false,
          categories: [
            {
              id: 1,
              value: 'Sala / Trabajo',
              checked: false,
            },
            {
              id: 2,
              value: 'Presentaciones',
              checked: false,
            },
            {
              id: 3,
              value: 'Conferencias',
              checked: false,
            },
            {
              id: 4,
              value: 'Actividades Integración',
              checked: false,
            },
            {
              id: 5,
              value: 'Cowork',
              checked: false,
            },
          ],
        },
        {
          id: 4,
          tipo: 'Celebraciones',
          selected: false,
          categories: [
            {
              id: 1,
              value: 'Brunch',
              checked: false,
            },
            {
              id: 2,
              value: 'Cumpleaños',
              checked: false,
            },
            {
              id: 3,
              value: 'Aniversarios',
              checked: false,
            },
            {
              id: 4,
              value: 'Baby shower',
              checked: false,
            },
            {
              id: 5,
              value: 'Fiestas infantiles',
              checked: false,
            },
            {
              id: 6,
              value: 'Bodas',
              checked: false,
            },
            {
              id: 7,
              value: 'Fiestas',
              checked: false,
            },
            {
              id: 8,
              value: 'Bautizos',
              checked: false,
            },
          ],
        },
        {
          id: 5,
          tipo: 'Emprendimiento',
          selected: false,
          categories: [
            {
              id: 1,
              value: 'Showroom',
              checked: false,
            },
            {
              id: 2,
              value: 'Exposiciones',
              checked: false,
            },
            {
              id: 3,
              value: 'Espacio en tienda',
              checked: false,
            },
          ],
        },
        {
          id: 6,
          tipo: 'Entretenimiento',
          selected: false,
          categories: [
            {
              id: 1,
              value: 'Presentación Teatral / Musical',
              checked: false,
            },
            {
              id: 2,
              value: 'Presentación producto ',
              checked: false,
            },
            {
              id: 3,
              value: 'Artístico / Cultural',
              checked: false,
            },
          ],
        },
        {
          id: 7,
          tipo: 'Cursos y Talleres',
          selected: false,
          categories: [
            {
              id: 1,
              value: 'Curso de Fotografia, yoga, similares',
              checked: false,
            },
            {
              id: 2,
              value: 'Taller Integración',
              checked: false,
            },
            {
              id: 3,
              value: 'Estimulación temprana, niños',
              checked: false,
            },
            {
              id: 4,
              value: 'Workshops',
              checked: false,
            },
          ],
        },
        {
          id: 8,
          tipo: 'Momentos Especiales',
          categories: [
            {
              id: 1,
              value: 'Pedida de Mano',
              checked: false,
            },
            {
              id: 2,
              value: 'Cenas privadas',
              checked: false,
            },
            {
              id: 3,
              value: 'Despedidas',
              checked: false,
            },
          ],
        },
      ],
    };
    return of(data);
  }

  getDays() {
    let data = {
      rtn: true,
      result: [
        {
          name: 'Lun',
          value: 1,
          checked: false,
        },
        {
          name: 'Mar',
          value: 2,
          checked: false,
        },
        {
          name: 'Mier',
          value: 3,
          checked: false,
        },
        {
          name: 'Jue',
          value: 4,
          checked: false,
        },
        {
          name: 'Vie',
          value: 5,
          checked: false,
        },
        {
          name: 'Sab',
          value: 6,
          checked: false,
        },
        {
          name: 'Dom',
          value: 0,
          checked: false,
        },
      ],
    };
    return of(data);
  }

  getHours(){
    let data = [
      { "id": 0, "label": "00:00 am - 01:00 am", "selected": false, "disabled": false },
      { "id": 1, "label": "01:00 am - 02:00 am", "selected": false, "disabled": false },
      { "id": 2, "label": "02:00 am - 03:00 am", "selected": false, "disabled": false },
      { "id": 3, "label": "03:00 am - 04:00 am", "selected": false, "disabled": false },
      { "id": 4, "label": "04:00 am - 05:00 am", "selected": false, "disabled": false },
      { "id": 5, "label": "05:00 am - 06:00 am", "selected": false, "disabled": false },
      { "id": 6, "label": "06:00 am - 07:00 am", "selected": false, "disabled": false },
      { "id": 7, "label": "07:00 am - 08:00 am", "selected": false, "disabled": false },
      { "id": 8, "label": "08:00 am - 09:00 am", "selected": false, "disabled": false },
      { "id": 9, "label": "09:00 am - 10:00 am", "selected": false, "disabled": false },
      { "id": 10, "label": "10:00 am - 11:00 am", "selected": false, "disabled": false },
      { "id": 11, "label": "11:00 am - 12:00 pm", "selected": false, "disabled": false },
      { "id": 12, "label": "12:00 pm - 13:00 pm", "selected": false, "disabled": false },
      { "id": 13, "label": "13:00 pm - 14:00 pm", "selected": false, "disabled": false },
      { "id": 14, "label": "14:00 pm - 15:00 pm", "selected": false, "disabled": false },
      { "id": 15, "label": "15:00 pm - 16:00 pm", "selected": false, "disabled": false },
      { "id": 16, "label": "16:00 pm - 17:00 pm", "selected": false, "disabled": false },
      { "id": 17, "label": "17:00 pm - 18:00 pm", "selected": false, "disabled": false },
      { "id": 18, "label": "18:00 pm - 19:00 pm", "selected": false, "disabled": false },
      { "id": 19, "label": "19:00 pm - 20:00 pm", "selected": false, "disabled": false },
      { "id": 20, "label": "20:00 pm - 21:00 pm", "selected": false, "disabled": false },
      { "id": 21, "label": "21:00 pm - 22:00 pm", "selected": false, "disabled": false },
      { "id": 22, "label": "22:00 pm - 23:00 pm", "selected": false, "disabled": false },
      { "id": 23, "label": "23:00 pm - 00:00 am", "selected": false, "disabled": false }
    ]
    return of(data);
  }

  contactOwner(request){
    const url = environment.apiUrl + Endpoints.CONTACT_OWNER;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  sendPreReserve(request){
    const url = environment.apiUrl + Endpoints.SEND_PRERESERVE;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  payReserve(request){
    const url = environment.apiUrl + Endpoints.PAY_RESERVE;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  sendPayment(request){
    const url = environment.apiUrl + Endpoints.SEND_PAYMENT;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  getReserves(tipo){
    const url = environment.apiUrl + Endpoints.GET_RESERVES + tipo;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.get<any>(url, options);
  }

  getQuiz(tipo){
    const url = environment.apiUrl + Endpoints.GET_QUIZ + tipo;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.get<any>(url, options);
  }

  sendValorationUser(request){
    const url = environment.apiUrl + Endpoints.VALORATION_USER;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  sendValorationMoment(request){
    const url = environment.apiUrl + Endpoints.VALORATION_MOMENT;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  getFinance(momentId){
    const url = environment.apiUrl + Endpoints.GET_FINANCE + momentId;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.get<any>(url, options);
  }

  filterMoments(momentoId, subcategorieId, capacity, dateInit, dateEnd){
    const url = environment.apiUrl + Endpoints.GET_FILTER_MOMENTS + `${momentoId}/${subcategorieId}/${capacity}` +
    ( dateInit ? `/${dateInit}/${dateEnd}` : '' );
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.get<any>(url, options);
  }

  approbeRequest(request){
    const url = environment.apiUrl + Endpoints.APPROBE_REQUEST;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }

  changeState(request){
    const url = environment.apiUrl + Endpoints.CHANGE_ACTIVE;
    const options = this.httpService.headerOptionsJson(true, false);
    return this.httpClient.post<any>(url, request, options);
  }
}
