import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule) },
  { path: '', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule) },
  { path: '', loadChildren: () => import('./components/new-experience/new-experience.module').then(m => m.NewExperienceModule) },
  { path: '', loadChildren: () => import('./components/register/register.module').then(m => m.RegisterModule) },
  { path: '', loadChildren: () => import('./components/experience/experience.module').then(m => m.ExperienceModule) },
  { path: '', loadChildren: () => import('./components/reserves/reserves.module').then(m => m.ReservesModule) },
  { path: '', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) },
  { path: '', loadChildren: () => import('./components/calendar/calendar.module').then(m => m.CalendarModule) },
  { path: '', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule) },
  { path: '', loadChildren: () => import('./components/messages/messages.module').then(m => m.MessageModule) },
  { path: '', loadChildren: () => import('./components/messages-client/messages-client.module').then(m => m.MessagesClientModule) },
  { path: '', loadChildren: () => import('./components/favorites/favorites.module').then(m => m.FavoritesModule) },
  { path: '', loadChildren: () => import('./components/pay-reserve/pay-reserve.module').then(m => m.PayReserveModule) },
  { path: '', loadChildren: () => import('./components/forget-password/forget-password.module').then(m => m.ForgetPasswordModule) },
  { path: '', loadChildren: () => import('./components/support/support.module').then(m => m.SupportModule) },
  { path: '', loadChildren: () => import('./components/account-data/account-data.module').then(m => m.AccountDataModule) },
  // { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
