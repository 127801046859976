<div class="d-flex flex-column h-100 content-information p-sm-5" [ngClass]="isPlatform ? 'p-4':'p-0'">
  <!-- <div [ngClass]="isPlatform ? 'content-view':''">
    <div [ngClass]=" isPlatform ? 'max-height':'' "> -->
      <form [formGroup]="passwordForm">
        <div class="content-form-forget w-100 d-flex flex-column" [ngClass]="isPlatform ? '':'m-auto'">
          <div>
            <span class="fw-bold" [ngClass]="isPlatform ? 'fs-2':['text-white','fs-1']">
              {{isPlatform ? 'Cambiar contraseña':'Olvidé mi contraseña'}}
            </span>
          </div>
          <ng-container *ngIf="isPlatform">
            <div>
              <div class="mb-2">
                <span class="title" [ngClass]="isPlatform ? '':'text-white'">Ingrese contraseña actual</span>
              </div>
              <nz-input-group [nzSuffix]="suffixTemplate1" class="password d-flex al-center shadow-sm">
                <input
                  [type]="hideAc ? 'text' : 'password'"
                  nz-input class="fs-6"
                  placeholder="Ingresa su contraseña actual"
                  formControlName="passwordCurrent"
                />
              </nz-input-group>
              <ng-template #suffixTemplate1>
                <span
                  nz-icon
                  [nzType]="hideAc ? 'eye-invisible' : 'eye'"
                  (click)="hideAc = !hideAc" class="fs-4 pointer"
                ></span>
              </ng-template>
            </div>
          </ng-container>
          <div>
            <div class="mb-2">
              <span class="title" [ngClass]="isPlatform ? '':'text-white'">Ingrese nueva contraseña</span>
            </div>
            <nz-input-group [nzSuffix]="suffixTemplate2" class="password d-flex al-center shadow-sm">
              <input
                [type]="hide ? 'text' : 'password'"
                nz-input class="fs-6"
                placeholder="Ingresa una contraseña"
                formControlName="newPassword"
              />
            </nz-input-group>
            <ng-template #suffixTemplate2>
              <span
                nz-icon
                [nzType]="hide ? 'eye-invisible' : 'eye'"
                (click)="hide = !hide" class="fs-4 pointer"
              ></span>
            </ng-template>
          </div>
          <div>
            <div class="mb-2">
              <span class="title" [ngClass]="isPlatform ? '':'text-white'">Confirmar nueva contraseña</span>
            </div>
            <nz-input-group [nzSuffix]="suffixTemplate3" class="password d-flex al-center shadow-sm">
              <input
                [type]="hideRe ? 'text' : 'password'"
                nz-input class="fs-6"
                placeholder="Ingresa una contraseña"
                formControlName="repeatPassword"
              />
            </nz-input-group>
            <ng-template #suffixTemplate3>
              <span
                nz-icon
                [nzType]="hideRe ? 'eye-invisible' : 'eye'"
                (click)="hideRe = !hideRe" class="fs-4 pointer"
              ></span>
            </ng-template>
            <ng-container *ngIf="passwordWrong">
              <div class="mt-3">
                <span class="fs-7 fw-regular color-red">El campo ingresado no coincide con la nueva contraseña.</span>
              </div>
            </ng-container>
          </div>
          <div>
            <ul>
              <li [ngClass]="isPlatform ? 'text-dark' : 'text-white'">Al menos 8 caractéres</li>
              <li [ngClass]="isPlatform ? 'text-dark' : 'text-white'">1 letra Mayúscula</li>
              <li [ngClass]="isPlatform ? 'text-dark' : 'text-white'">1 caracter especial. Ejm @, $ o #</li>
              <li [ngClass]="isPlatform ? 'text-dark' : 'text-white'">Contraseñas iguales</li>
            </ul>
          </div>
          <div [ngClass]="isPlatform ? 'line-gray':'line'"></div>
          <div>
            <button nz-button class="w-100 btn-medium shadow-sm border-0 rounded d-flex al-center sp-center"
              [ngClass]="{'bg-black': isPlatform,'bg-orange': !isPlatform,'disabled': !validateForm()}" (click)="changePassword()">
              <span class="text-white fs-4">{{isPlatform ? 'Guardar cambios':'Cambiar contraseña'}}</span>
            </button>
          </div>
        </div>
      </form>
    <!-- </div>
  </div> -->
</div>
<ng-container *ngIf="loader">
  <app-loader></app-loader>
</ng-container>