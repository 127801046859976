import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Months } from 'src/app/enums/months.enum';

@Component({
  selector: 'app-detail-reserve',
  templateUrl: './detail-reserve.component.html',
  styleUrls: ['./detail-reserve.component.scss']
})
export class DetailReserveComponent implements OnInit {
  isOwner: boolean = false;
  isPostDay: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public reserve: any,
    private datepipe: DatePipe,
    private router: Router
  ) { }

  ngOnInit() {
    this.isOwner = this.router.url == '/reserved-moments' ? false : true;
  }

  getDate(dateReserve){
    let transformDate = this.datepipe.transform(dateReserve,'dd/MM/yyyy')
    let date = transformDate.split('/');
    let month = Months.find((element)=>element.id == date[1])
    return date[0] + ' de '+ month.value +' del '+ date[2];
  }

  getHours(dateStart, dateEnd){
    let transformDateStart = this.datepipe.transform(dateStart,'HH:mm aa');
    let transformDateEnd = this.datepipe.transform(dateEnd,'HH:mm aa');
    this.isPostDay = new Date(dateEnd) > new Date(dateStart) ? true : false;
    return 'Desde las ' + transformDateStart + ' hasta  las '+ transformDateEnd;
  }

  getAmountPropertie(reserve){
    let amount = ( Number(reserve.total_con_garantia) - (Number(reserve.fee_w2m) + Number(reserve.impuesto)) ).toFixed(2);
    return amount;
  }

}
