import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { RegisterService } from 'src/app/services/registro.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {
  @Input() showInputPassword: boolean = true;
  @Input() isPlatform : boolean = true;
  registerForm : FormGroup;
  months:Array<any>=[];
  days: Array<any>=[];
  years: Array<any>=[];
  isLoading: boolean = false;
  hide: boolean;
  loader: boolean = false;
  showAddres: boolean = true;
  usuario: any;
 
  constructor(
    private registerservice: RegisterService,
    private storeservice: StateService,
    private userservice: UserService,
    private commonservice: CommonService,
    private router: Router
  ) { 
  }

  ngOnInit() {
    this.storeservice.getProfileStore().subscribe(data => {
      this.usuario = data;
    });
    this.formBuilder();
    this.setDate();
    if (this.usuario) {
      const data = {
        nombre: this.usuario.nombres,
        apellido: this.usuario.apellidos,
        pais_id: 1,
        celular: this.usuario.celular,
        dia: this.usuario.fec_nacimiento.split('-')[2],
        mes: this.usuario.fec_nacimiento.split('-')[1],
        anio: this.usuario.fec_nacimiento.split('-')[0],
        country: this.usuario.pais,
        direccion: this.usuario.direccion,
        presentacion: this.usuario.presentacion
      }
      this.registerForm.patchValue(data);
    }

    if (this.router.url.includes('register')) {
      this.showAddres = false;
      this.registerForm.controls.passwordCurrent.setValidators(Validators.required);
    }
  }

  formBuilder(){
    this.registerForm = new FormGroup({
      nombre: new FormControl('', Validators.required),
      apellido: new FormControl('', Validators.required),
      pais_id: new FormControl(1),
      celular: new FormControl('',Validators.required),
      fec_nacimiento: new FormControl(''),
      dia: new FormControl('', Validators.required),
      mes: new FormControl('', Validators.required),
      anio: new FormControl('', Validators.required),
      contrasenia: new FormControl(''),
      passwordCurrent: new FormControl(''),
      country: new FormControl(''),
      direccion: new FormControl(''),
      presentacion: new FormControl('')
    });
  }

  register(){
    this.loader = true;
    this.registerForm.controls.fec_nacimiento.setValue(this.registerForm.value.anio+'-'+this.registerForm.value.mes+'-'+this.registerForm.value.dia);
    this.registerForm.controls.contrasenia.setValue(this.registerForm.value.passwordCurrent);
    if (this.usuario) {
      const message = 'Los datos de tu perfil han sido actualizados correctamente.'
      this.userservice.updateUser(this.registerForm.value).subscribe(
        (response)=>{
          this.loader = false;
          if (response.rtn) {
            this.isLoading = false;
            this.commonservice.showAlert(message)
          }
        }
      );
    }else{
      let token = this.storeservice.getTokenCode;
      this.registerservice.registerUser(this.registerForm.value, token).subscribe(
        (response)=>{
          this.isLoading = false;
          if (response.rtn && response.result == 1) {
            this.login();
          }
        }
      );
    }
  }

  setDate(){
    //days
    for (let index = 1; index < 32; index++) {
      this.days.push(index.toString().padStart(2, '0'));
    }
    //month
    this.months = [
      {id:'01', name:'Enero'},
      {id:'02', name:'Febrero'},
      {id:'03', name:'Marzo'},
      {id:'04', name:'Abril'},
      {id:'05', name:'Mayo'},
      {id:'06', name:'Junio'},
      {id:'07', name:'Julio'},
      {id:'08', name:'Agosto'},
      {id:'09', name:'Septiembre'},
      {id:'10', name:'Octubre'},
      {id:'11', name:'Noviembre'},
      {id:'12', name:'Diciembre'}
    ];
    //years
    let yearCurrent = (new Date()).getFullYear();
    for (let index = yearCurrent-18 ; index > yearCurrent-100 ; index--) {
      this.years.push(index.toString());
    }

  }

  login(){
    this.isLoading = true;
    const request = {
      ingreso: 1,
      email: this.storeservice.getEmail,
      contrasenia: this.registerForm.value.contrasenia
    }
    this.registerservice.login(request).subscribe(
      (response)=>{
        this.isLoading = false;
        if (response.rtn) {
          localStorage.setItem('JWT',response.result);
          this.getProfile();
        }
      }
    );
  }

  getProfile(){
    this.isLoading = true;
    this.userservice.getUser(1).subscribe(
      (response)=>{
        this.isLoading = false;
        if (response.rtn) {
          this.storeservice.profileStore.next(response.result)
          this.goTo('/moments-logged');
        }
      }
    );
  }

  goTo(url:string){
    this.router.navigateByUrl(url);
  }

  isRegister(): boolean {
    let routerFound = this.router.url.includes('register');
    return routerFound ? true : false;
  }


}
